.normal-button {
    background-image: url(./Sideboard_icon_light.png);
    background-size : 50px 30px;
    background-repeat: no-repeat;
    height: 30px;
    width: 35px;
    float: right;
    margin-right: 10px;
    margin-top: 25px;
    align-content: center;
    align-items: center;
    align-self: center;   
    border: none;
    background-color:rgba(1,1,1,0.01);
}

.button-inline {
    background-image: url(./Sideboard_icon_light.png);
    background-size : 50px 30px;
    background-repeat: no-repeat;
    height: 30px;
    width: 35px;
    float: right;
    margin-right: 10px;
    margin-top: 25px;
    border: none;
    background-color:rgba(1,1,1,0.01);
    padding-right: 10px;
}

.button-column {
    float: right;
}

.FadedColumn {
    position: fixed;
    top: 0;
    right: 0;
    float: right;
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1,0.4);
    
}

.SideBarColumn {
    float: right;
    width:50%;
    height: 100vh;
    background-color: rgb(31, 3, 36);
}

.SideDrawer {
    float: right;
    height: 100vh;
    width: 100%;
    position: relative;
}


.SideBar-ul {
    text-decoration: none;
    list-style: none;
}

.SideBar-li {
    padding-right: 10px;
    padding-top: 10%;
    padding-bottom: 10%;
    text-decoration: none;
}

.sideBarText {    
    font-size: 15px;
    text-decoration: none;
    float: right;
    color: white;
}

a:hover,
a:active{
    color: rgb(31, 199, 157);
}

.SideDrawerText {
    font-size: 15px;
    text-decoration: none;
    float: right;
}


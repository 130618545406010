.HorizontalPhotography {
    width: 100%;
    height: 100%;
    background-color: rgb(31, 3, 36);
}

.HorizontalPhotographyText {
    color: white;
    padding-top: 15%;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 5%;
    text-align: center;
    font-size: 3vw;
}

.HorizontalPhotographyLink {
    text-decoration: none;
    display: inherit;
    text-align:center;
    padding-left: 25%;
    padding-right: 25%;
    padding-top: 5%;
    padding-bottom: 5%;
    color: white;
    font-size: 3vw;   
}

.VerticalPhotography {
    width: 100%;
    height: 100%;
    background-color: rgb(31, 3, 36);
}

.VerticalPhotographyText {
    color: white;
    padding-top: 30%;
    padding-left: 5%;
    padding-right:5%;
    padding-bottom: 10%;
    text-align: center;
    font-size: 3vh;
}

.VerticalPhotographyLink {
    text-decoration: none;
    display:inherit;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
    font-size: 3vh;
}

a:hover,
a:active{
    color: rgb(31, 199, 157);
    
}
.HorizontalIntroductionRow {
    display: table;
    width:100%;
    height:100%;
    background-color: rgb(31, 3, 36);
}

.HorizontalImage {
    float: left;
    width: 35%;
}

.HorizontalTextColumn {
    float: right;
    width: 65%;
    height: 100%;
    vertical-align: center;
    text-align: center;
    background-color: rgb(31, 3, 36);
}

.HorizontalTextContainer {
    color:white;
    padding-top:20%;
    border-bottom: 20%;
}
.HorizontalIntroduction {
    font-size: 4vw;
    font-size-adjust: 5%;
    padding-left: 5%;
    padding-right: 5%;
}

.HorizontalBody {
    font-size: 3vw;
    padding-left: 5%;
}


.HorizontalTextContainer a{
    font-size: 2vw;
    color: white;
    text-decoration: none;
}

.VerticalRow{
    display: block;
    width:100%;
    height:100%;
}
.VerticalImage {
    display:block;
    width:100%;
    height: 100%;
    
}

.VerticalTextContainer{
    display:block;
    background-color: rgb(31, 3, 36);
    color:white;
}
.VerticalIntroduction{
    margin-top:0;
    margin-bottom:0;
    border:none;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
    padding-bottom:5%;
    font-size: 4vh;
}

.VerticalBody {
    padding:5%;
    padding-top:6%;
    padding-bottom: 10%;
    font-size: 4vh;
    margin-bottom: 0;
}

.VerticalTextContainer a {
    color: white;
    text-decoration: none;
    display: flex;
}

a:hover,
a:active{
    color: rgb(31, 199, 157);
}

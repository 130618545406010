.HorizontalMyCodeRow {
    width: 100%;
    height: 100%;
    background-color: rgb(31,3,36);
    padding-bottom: 5%;
}

.HorizontalTextHeading {
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
    font-size: 4vw;
    margin:0;

}



.HorizontalTextContainer {
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
    font-size: 3vw;
}

.HorizontalTextContainer a {
    font-size: 3vw;
}

.VerticalMyCodeRow {
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgb(31,3,36);
    padding-bottom: 5%;
}

.VerticalTextHeading {
    padding-top: 30%;
    padding-left: 5%;
    padding-right: 5%;
    color: white;
    font-size: 4vh;
    margin:0;
}

.VerticalTextContainer {
    padding-top: 15%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 3vh;
}

.VerticalTextContainer a {
    font-size: 3vh;
}